/**
 * @modules : node_modulesフォルダまでの絶対パスのエイリアス
 * webpack.config.jsにて定義している
 */

import $ from "@modules/jquery";
// import '@modules/slick-carousel';



("use strict");

// PC/SP判定
// スクロールイベント
// リサイズイベント
// スムーズスクロール

/* ここから */

const breakpoint = 640;
const mql = window.matchMedia(`screen and (max-width: ${breakpoint}px)`); //、MediaQueryListの生成
let deviceFlag = mql.matches ? 1 : 0; // 0 : PC ,  1 : SP

// pagetop
let timer = null;
const $pageTop = $('#pagetop');
$pageTop.hide();

// スクロールイベント
$(window).on('scroll touchmove', function () {

  // スクロール中か判定
  if (timer !== false) {
    clearTimeout(timer);
  }

  // スクロール量が100pxを超えたら、200ms後にフェードイン
  timer = setTimeout(function () {
    if ($(this).scrollTop() > 100) {
      $('#pagetop').fadeIn('normal');
    } else {
      $pageTop.fadeOut();
    }
  }, 200);

  const scrollHeight = $(document).height();
  const scrollPosition = $(window).height() + $(window).scrollTop();
  const footHeight = parseInt($('#footer').innerHeight());


  if (scrollHeight - scrollPosition <= footHeight - 20) {
    // 現在の下から位置が、フッターの高さの位置にはいったら(bottom20px分を引いて調整)
    $pageTop.css({
      'position': 'absolute',
      'bottom': footHeight,
    });
  } else {
    $pageTop.css({
      'position': 'fixed',
      'bottom': '20px'
    });
  }

});


// リサイズイベント
const checkBreakPoint = function (mql) {
  deviceFlag = mql.matches ? 1 : 0; // 0 : PC ,  1 : SP
  // → PC
  if (deviceFlag === 0) {
    console.log('PC');
  } else {
    // →SP
    console.log('SP');
  }

  deviceFlag = mql.matches;
}

// ブレイクポイントの瞬間に発火
mql.addListener(checkBreakPoint); //MediaQueryListのchangeイベントに登録

// 初回チェック
checkBreakPoint(mql);


// スムーズスクロール
// #で始まるアンカーをクリックした場合にスムーススクロール
$('a[href^="#"]').on('click', function () {
  const speed = 500;
  // アンカーの値取得
  const href = $(this).attr('href');
  // 移動先を取得
  const target = $(href == '#' || href == '' ? 'html' : href);
  // 移動先を数値で取得
  const position = target.offset().top;

  // スムーススクロール
  $('body,html').animate({
    scrollTop: position
  }, speed, 'swing');
  return false;
});


// googlefont非同期設定
window.WebFontConfig = {
  google: { families: ['Noto+Sans+JP:500,700','Barlow:wght@700'] },
  active: function() {
  sessionStorage.fonts = true;
  }
  };
  (function() {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();

// ハンバーガー
$(function() {
  $('.c-hamburger').click(function() {
      $(this).toggleClass('active');

      if ($(this).hasClass('active')) {
          $('.side').addClass('active');
          $('body').addClass('active');
      } else {
          $('.side').removeClass('active');
          $('body').removeClass('active');
      }
  });
});

// スクロールでクラス付与
const myFunc = function(){

  //Classを追加する要素を取得
  const target = document.getElementsByClassName('animation');
  //Classを追加する位置を指定（ビューポート内）
  const position = Math.floor(window.innerHeight * .75); //左記はビューポート内の上から75%の位置を指定

  //要素の数だけループする
  for (let i = 0; i < target.length; i++) {
      
      //要素の上部座標を取得する（小数点切り捨て）
      let offsetTop = Math.floor(target[i].getBoundingClientRect().top);

      //要素の上部座標がpositionの位置を通過したら
      if (offsetTop < position) {
          //要素にClassを追加する
          target[i].classList.add('is-animate');
      }
  }
}
//スクロールイベントリスナーに登録
window.addEventListener('scroll', myFunc, false);

// すべてのh3要素を取得
const headings = document.querySelectorAll('.side-cource__subtitle');

headings.forEach((heading) => {
  heading.addEventListener('click', () => {
    // クリックされたh3要素にshowクラスを付与または削除
    heading.classList.toggle('show');
    // クリックされたh3要素の次の兄弟要素（ul要素）にshowクラスを付与または削除する
    const list = heading.nextElementSibling;
    list.classList.toggle('show');
  });
});

// swiperの設定
const mySwiper_thumb = new Swiper('.swiper-thumb', {
  slidesPerView: 4,
  spaceBetween: 0,
  // navigation: {
  //   nextEl: '.swiper-thumb .swiper-button-next',
  //   prevEl: '.swiper-thumb .swiper-button-prev',
  // },
  breakpoints: {
    600: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
  },
});
 
const mySwiper_main = new Swiper('.swiper-main', {
  effect: 'fade',
  navigation: {
    nextEl: '.swiper-thumb .swiper-button-next',
    prevEl: '.swiper-thumb .swiper-button-prev',
  },
  fadeEffect: {
    crossFade: true,
  },
  thumbs: {
    swiper: mySwiper_thumb,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  loop: true,  // 無限ループさせる
  loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数
 
  speed: 300, // スライドアニメーションのスピード（ミリ秒）
 
  autoplay: { // 自動再生させる
    delay: 3000, // 次のスライドに切り替わるまでの時間（ミリ秒）
    disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
    // waitForTransition: false, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
  },
});

// swiper設定
const mySwiper = new Swiper('.slide01 .swiper', {
  slidesPerView: 1,
  spaceBetween: 28,
  grabCursor: true,
  autoplay: true,
  speed: 1200,
  pagination: {
    el: '.slide01 .swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.slide01 .swiper-button-next',
    prevEl: '.slide01 .swiper-button-prev',
  },
  breakpoints: {
    890: {
      spaceBetween: 32,
      slidesPerView: "auto",
    }
  },
});

// DOM要素を取得
const tabMenus = document.querySelectorAll('.js-tab');

// クリックイベントリスナーを追加
tabMenus.forEach((tabMenu) => {
  tabMenu.addEventListener('click', tabSwitch);
});

// イベント処理
function tabSwitch(e) {
  // クリックされた要素のデータ属性を取得
  const tabTargetData = e.currentTarget.dataset.tab;
  // クリックされた要素の親要素とその子要素を取得
  const tabList = e.currentTarget.closest('.js-tabs');
  const tabItems = tabList.querySelectorAll('.js-tab');
  const tabPanelItems = tabList.nextElementSibling.querySelectorAll('.tabcontent');

  // クリックされたtabの同じ階層のmenuとpanelのクラスを削除
  tabItems.forEach((tabItem) => {
    tabItem.classList.remove('is-active');
  });

  tabPanelItems.forEach((tabPanelItem) => {
    tabPanelItem.classList.remove('is-show');
  });

  // クリックされたmenu要素にis-activeクラスを付加
  e.currentTarget.classList.add('is-active');
  
  // クリックしたmenuのデータ属性と等しい値を持つパネルにis-showクラスを付加
  tabPanelItems.forEach((tabPanelItem) => {
    if (tabPanelItem.dataset.panel ===  tabTargetData) {
      tabPanelItem.classList.add('is-show');
    }
  });
}

// ページが読み込まれた後に実行
document.addEventListener('DOMContentLoaded', function () {
  // ボタンのクラスを指定して取得
  var searchButton = document.querySelector('.btn.btn-primary');

  // ボタンが存在するか確認
  if (searchButton) {
      // ボタンのテキストを変更
      searchButton.textContent = '絞り込む';
  }
});




// document.addEventListener('DOMContentLoaded', function () {
//   // ロード時に保存された選択状態を復元
//   restoreSelectedOptions();

//   var form = document.querySelector('.wp-block-vk-filter-search-filter-search.vk-filter-search.vkfs'); // フォーム要素を取得

//   // フォームが送信されたときの処理
//   form.addEventListener('submit', function (event) {
//     // post-type-archive-course の場合のみ選択された学年と目的の値を保存
//     if (isPostTypeArchiveCourse()) {
//       saveSelectedOptions();
//     }
//   });

//   // セレクトボックスが変更されたときに新たな検索が行われたとみなし、クッキーを書き換えて保存
//   form.addEventListener('change', function (event) {
//     saveSelectedOptions();
//   });
// });

// // post-type-archive-course であるかどうかを判定
// function isPostTypeArchiveCourse() {
//   return document.body.classList.contains('post-type-archive-course');
// }

// // 保存された選択状態を復元
// function restoreSelectedOptions() {
//   // post-type-archive-course であるかつ archive-course.php ページで検索が行われている場合のみ復元
//   if (isPostTypeArchiveCourse() && isArchiveCoursePage()) {
//     var selectedGrade = getCookie('selectedGrade');
//     var selectedPurpose = getCookie('selectedPurpose');

//     if (selectedGrade !== null) {
//       document.getElementById('vkfs_grade').value = selectedGrade;
//     }

//     if (selectedPurpose !== null) {
//       document.getElementById('vkfs_purpose').value = selectedPurpose;
//     }
//   }
// }

// // 選択された学年と目的の値を保存
// function saveSelectedOptions() {
//   var selectedGrade = document.getElementById('vkfs_grade').value;
//   var selectedPurpose = document.getElementById('vkfs_purpose').value;

//   setCookie('selectedGrade', selectedGrade, 365); // クッキーの有効期限を1年に設定
//   setCookie('selectedPurpose', selectedPurpose, 365);
// }

// // クッキーを設定
// function setCookie(name, value, days) {
//   var expires = '';
//   if (days) {
//     var date = new Date();
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//     expires = '; expires=' + date.toUTCString();
//   }
//   document.cookie = name + '=' + value + expires + '; path=/';
// }

// // クッキーを取得
// function getCookie(name) {
//   var nameEQ = name + '=';
//   var ca = document.cookie.split(';');
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// }

// // archive-course.php のページであるかどうかを判定
// function isArchiveCoursePage() {
//   return document.body.classList.contains('archive') && document.body.classList.contains('post-type-archive-course');
// }

document.addEventListener('DOMContentLoaded', function () {
  // ロード時に保存された選択状態を復元
  restoreSelectedOptions();

  // フォーム要素を取得
  var forms = document.querySelectorAll('.wp-block-vk-filter-search-filter-search.vk-filter-search.vkfs');

  // 各フォームに対して処理を行う
  forms.forEach(function (form) {
    // フォームが送信されたときの処理
    form.addEventListener('submit', function (event) {
      // post-type-archive-course の場合のみ選択された学年と目的の値を保存
      if (isPostTypeArchiveCourse()) {
        saveSelectedOptions(form); // フォームを引数として渡す
      }
    });

    // セレクトボックスが変更されたときに新たな検索が行われたとみなし、クッキーを書き換えて保存
    form.addEventListener('change', function (event) {
      saveSelectedOptions(form); // フォームを引数として渡す
    });
  });
});

// post-type-archive-course であるかどうかを判定
function isPostTypeArchiveCourse() {
  return document.body.classList.contains('post-type-archive-course');
}

// 保存された選択状態を復元
function restoreSelectedOptions() {
  // post-type-archive-course であるかつ archive-course.php ページで検索が行われている場合のみ復元
  if (isPostTypeArchiveCourse() && isArchiveCoursePage()) {
    var forms = document.querySelectorAll('.wp-block-vk-filter-search-filter-search.vk-filter-search.vkfs');
    forms.forEach(function (form) {
      var selectedGrade = getCookie(form, 'selectedGrade');
      var selectedPurpose = getCookie(form, 'selectedPurpose');

      if (selectedGrade !== null) {
        form.querySelector('.vkfs__input-wrap--grade').value = selectedGrade;
      }

      if (selectedPurpose !== null) {
        form.querySelector('.vkfs__input-wrap--purpose').value = selectedPurpose;
      }
    });
  }
}

// 選択された学年と目的の値を保存
function saveSelectedOptions(form) {
  var selectedGrade = form.querySelector('.vkfs__input-wrap--grade').value;
  var selectedPurpose = form.querySelector('.vkfs__input-wrap--purpose').value;

  setCookie(form, 'selectedGrade', selectedGrade, 365); // クッキーの有効期限を1年に設定
  setCookie(form, 'selectedPurpose', selectedPurpose, 365);
}

// クッキーを設定
function setCookie(form, name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + value + expires + '; path=/';
}

// クッキーを取得
function getCookie(form, name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// archive-course.php のページであるかどうかを判定
function isArchiveCoursePage() {
  return document.body.classList.contains('archive') && document.body.classList.contains('post-type-archive-course');
}
